<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Berikut adalah <b>Daftar Akuntan</b> yang ada di Klinik IONA
      </div>
      <b-button
        squared
        variant="success"
        @click="$router.push({ name: 'accountant-add' })"
      >Tambah</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <div>

              <HrTable
                :table="table"
                :route="'accountant'"
              /> 

            </div>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import HrTable from '@/component/human-resource/Table.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  name: 'List',

  components: {
    Card,
    HrTable
  },

  data() {
    return {
      table: {
        fields: [
          {
            key: "id",
            sortable: true,
          },
          {
            key: "name",
            label: "Nama",
            sortable: true,
          },
          {
            key: "email",
            label: "Email",
            sortable: true,
          },
          {
            key: "phone",
            label: "Nomor Telepon",
            sortable: true,
          },
          {
            key: "dueBalance",
            label: "Sisa Deposit",
            sortable: true,
          },
          { key: "actions", label: "Aksi" },
        ],
        items: [
          {
            isActive: true,
            id: 1,
            name: "Andi Yulianto",
            email: "a.yulianto@email.com",
            phone: "+628123456789",
            dueBalance: "Rp.5.000.000",
          },
          {
            isActive: true,
            id: 2,
            name: "Galih Utomo",
            email: "gutomo@example.com",
            phone: "+628123456789",
            dueBalance: "Rp.3.000.000",
          },
          {
            isActive: true,
            id: 3,
            name: "Oma Jeanne",
            email: "jeanne.k@yahoo.com",
            phone: "+628123456789",
            dueBalance: "Rp.0",
          },
          {
            isActive: true,
            id: 4,
            name: "M Priyadi",
            email: "m.priyadi@kampus.com",
            phone: "+628123456789",
            dueBalance: "Rp.25.000.000",
          },
          {
            isActive: true,
            id: 5,
            name: "Ant. Sunardo",
            email: "a.sunardo@gmail.com",
            phone: "+628123456789",
            dueBalance: "Rp.15.000.000",
          },
        ],
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Akuntan" },
      { title: "Daftar Akuntan" },
    ])
  },

}
</script>

<style>
</style>